import $ from 'jquery'
import 'swiped-events'

class tinderControl {
    constructor() {
        var _this = this;
        var selections = [];
        // var card = null;
        
        if ($('.card-list').length) {
            console.log('tinderControl starts');

            // Binds
            $('.action-like').each(function() {
                $(this).on('click', function() {
                    if ($(this).hasClass('action-like--active')) {
                        return false;
                    }

                    var card = $(this).parents('.card-list__item');

                    setLoading(true, 'like', card);

                    $.get("http://api-dev.usabitspace.com/api/Default/health-check", function(data, status){
                        // alert("Data: " + data + "\nStatus: " + status);
                        card.find('.action-like').addClass('action-like--active');
                        card.find('.action-dislike').removeClass('action-dislike--active');
                        showFeedback('like', card);
                        updateSelection('add', card);
                    })
                    .fail(function() {
                    })
                    .always(function() {
                        setLoading(false, 'like', card);
                    });
                });
            });
            $('.action-dislike').each(function() {
                $(this).on('click', function() {
                    if ($(this).hasClass('action-dislike--active')) {
                        return false;
                    }

                    var card = $(this).parents('.card-list__item');
    
                    setLoading(true, 'dislike', card);

                    $.get("http://api-dev.usabitspace.com/api/Default/health-check", function(data, status){
                        card.find('.action-dislike').addClass('action-dislike--active')
                        card.find('.action-like').removeClass('action-like--active')
                        showFeedback('dislike', card);
                        updateSelection('remove', card);
                    })
                    .fail(function() {
                    })
                    .always(function() {
                        setLoading(false, 'dislike', card);
                    });
                });

            });
            $('.nav-next').each(function() {
                $(this).on('click', function() {
                    var card = $(this).parents('.card-list__item');
                    goTo('next', card);
                });
            });
            $('.nav-prev').each(function() {
                $(this).on('click', function() {
                    var card = $(this).parents('.card-list__item');
                    goTo('prev', card);
                });
            });

            $('.card-list__item').each(function() {
                $(this).on('swiped-left', function() {
                    console.log('>', $(this));
                    goTo('next', $(this));
                });
                $(this).on('swiped-right', function() {
                    console.log('>', $(this));
                    goTo('prev', $(this));
                });
            });

            $('.steps__helper').on('click', function() {
                showHelper(true);
            });
            $('.helper').on('click', function() {
                showHelper(false);
            });
        }

        // Functions
        function setLoading(active, type, card) {
            console.log('active', active, type);

            if (active) {
                card.find('.card-list__item-controls').addClass('card-list__item-controls--' + type + '-loading');

            } else {
                card.find('.card-list__item-controls').removeClass('card-list__item-controls--' + type + '-loading');
            }
        };
        function showFeedback(type, card) {
            var cardFeedback = '';
                cardFeedback += '<div class="card-list__feedback">';
                    cardFeedback +=  '<div class="selected selected--' + type + '">';
                        if (type == 'like') {
                            cardFeedback += '<svg class="selected__icon" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">';
                                cardFeedback += '<circle cx="34.5" cy="34.5" r="34.5" fill="#7D07B3"/>';
                                cardFeedback += '<circle cx="34.5" cy="34.5" r="34.25" stroke="#D1C7E2" stroke-opacity="0.5" stroke-width="0.5"/>';
                                cardFeedback += '<path d="M20.7961 30.3654L30.0186 29.0016L34.1524 20.4774C34.4611 19.8409 35.5399 19.8409 35.8486 20.4774L39.9811 29.0016L49.2036 30.3654C49.9611 30.478 50.2636 31.3698 49.7323 31.8962L43.0323 38.5447L44.6161 47.9438C44.7423 48.6905 43.9186 49.2508 43.2373 48.8841L34.9999 44.4768L26.7624 48.8853C26.0874 49.2484 25.2561 48.699 25.3836 47.945L26.9674 38.5459L20.2674 31.8974C19.7361 31.3698 20.0399 30.478 20.7961 30.3654Z" fill="white"/>';
                            cardFeedback += '</svg>';
                        } else {
                            cardFeedback += '<svg class="selected__icon" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">';
                                cardFeedback += '<circle cx="34.5" cy="34.5" r="34.5" fill="#AB1111"/>';
                                cardFeedback += '<path d="M35 20C26.7559 20 20 26.7559 20 35C20 43.2441 26.7559 50 35 50C43.2441 50 50 43.2441 50 35C50 26.7559 43.2441 20 35 20ZM23.5742 35C23.5742 28.707 28.707 23.5742 35 23.5742C37.373 23.5742 39.6583 24.3124 41.6094 25.7011L35 32.3105L25.7012 41.6094C24.3124 39.6581 23.5742 37.373 23.5742 35ZM35 46.4258C32.627 46.4258 30.3417 45.6874 28.3906 44.2988L44.2988 28.3906C45.6876 30.3417 46.4258 32.6269 46.4258 35C46.4258 41.2929 41.293 46.4258 35 46.4258Z" fill="white"/>';
                                cardFeedback += '<path d="M50 35C50 43.2441 43.2441 50 35 50V46.4258C41.293 46.4258 46.4258 41.2929 46.4258 35C46.4258 32.6269 45.6876 30.3417 44.2988 28.3906L35 37.6894V32.3105L41.6094 25.7011C39.6583 24.3124 37.373 23.5742 35 23.5742V20C43.2441 20 50 26.7559 50 35Z" fill="white"/>';
                            cardFeedback += '</svg>';
                        }
                        cardFeedback += '<h2 class="selected__title">';
                        cardFeedback += card.find('h2').text();
                        if (type == 'like') {
                            cardFeedback += '<span>Selecionado</span></h2>';
                        } else {
                            cardFeedback += '<span>Rejeitado</span></h2>';
                        }
                        cardFeedback += '<p class="selected__text">';
                        cardFeedback += cardFeedback += card.find('h3').text();
                        cardFeedback += '</p>';
                    cardFeedback += '</div>';
                cardFeedback += '</div>';

                $(cardFeedback).insertBefore(card);

                if (type == 'like') {
                    card.addClass('card-list__item--yes');
                    setTimeout(function() {
                        card.removeClass('card-list__item--yes');
                    }, 700);
                } else {
                    card.addClass('card-list__item--nope');
                    setTimeout(function() {
                        card.removeClass('card-list__item--nope');
                    }, 700);
                }

                setTimeout(function() {
                    goTo('next', card);
                }, 700);
        }
        function goTo(direction, card) {
            if ($('.card-list__feedback').length > 0) {
                // Like and Dislike Actions
                card.removeClass('card-list__item--current');

                setTimeout(function() {
                    if (direction == 'next') {
                        if (card.hasClass('card-list__item--last')) {
                            $('.card-list__item').last().addClass('card-list__item--current');
                        } else {
                            $('.card-list__feedback').prev().addClass('card-list__item--current');
                        }
                    } else {
                        card.next().addClass('card-list__item--current');
                    }
                    
                    setCounter($('.card-list__item--current').attr('data-position'));
                    $('.card-list__feedback').fadeOut().remove();
                }, 2000);
            } else {
                // Next and Prev Buttons
                if (direction == 'next') {
                    card.addClass('card-list__item--next');
                    if (card.prev().length) {
                        card.prev().addClass('card-list__item--current');
                    } else {
                        $('.card-list__item').last().addClass('card-list__item--current');
                    }
                    
                    setTimeout(function() {
                        card.removeClass('card-list__item--current');
                        card.removeClass('card-list__item--next');
                        setCounter($('.card-list__item--current').attr('data-position'));
                    }, 700);
                } else {
                    if (card.next().length) {
                        card.next().addClass('card-list__item--prev');
                        setTimeout(function() {
                            card.next().removeClass('card-list__item--prev');
                            card.removeClass('card-list__item--current');
                            card.next().addClass('card-list__item--current');
                            setCounter($('.card-list__item--current').attr('data-position'));
                        }, 700);
                    } else {
                        $('.card-list__item').first().addClass('card-list__item--prev');
                        setTimeout(function() {
                            $('.card-list__item').first().removeClass('card-list__item--prev');
                            card.removeClass('card-list__item--current');
                            $('.card-list__item').first().addClass('card-list__item--current');
                            setCounter($('.card-list__item--current').attr('data-position'));
                        }, 700);
                    }
                }
            }

        }
        function setCounter(position) {
            if ($('body').hasClass('page-tinder')) {
                $('.steps__counter span').text(position + '/' + $('.card-list__item').length);
            }
        }
        function showHelper(show) {
            if (show) {
                $('.helper').fadeIn('fast');
            } else {
                $('.helper').fadeOut('fast');
            }
        }
        function updateSelection(action, card) {
            if (action == 'add') {
                selections.push(card.attr('data-id'));
            } else {
                selections.splice(selections.indexOf(card.attr('data-id')), 1);
            }

            console.log('selections', selections);
            $('.selection-bar__selected-numbers span').text(selections.length + '/10');
            if (selections.length) {
                activeSelectionBar(true);
            } else {
                activeSelectionBar(false);
            }
        }
        function activeSelectionBar(show) {
            if (show) {
                setTimeout(function() {
                    $('.selection-bar').addClass('selection-bar--active');
                }, 1400);
            } else {
                $('.selection-bar').removeClass('selection-bar--active');
            }
        }
        function init() {
            setCounter(1);
            showHelper(true);
        }

        // Init
        init();
      }
    }
    
export default tinderControl