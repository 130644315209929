import $ from 'jquery'
import slick from "slick-carousel-latest/slick/slick"

class Slider {
  constructor() {
    var _this = this;
    _this.photoList = $('.card-list__item-photos');

    console.log('>>>', _this.photoList);
    
    if (_this.photoList) {
      _this.photoList.each(function() {
        var slider = $(this);

        slider.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          dots: true,
          arrows: false,
          focusOnSelect: true,
          draggable: false,
          swipe: false,
          touchMove: true
        });
  
        slider.find('.card-list__item-photos-item').on('click', function() {
          slider.slick('slickGoTo', parseInt(slider.slick('slickCurrentSlide'))+1);
        });
  
        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
          
        });

      });
    }
  }
}

export default Slider