import $ from 'jquery'
import 'jquery-mask-plugin';
import Croppie from 'croppie';

class FormValidation {
    constructor() {
        const applyMask = () => {
            $('#cpf').mask('000.000.000-00', {reverse: true});
            $('#birth').mask('00/00/0000');
        }
        const debounce = (func, delay) => {
            let debounceTimer
            return function () {
                const context = this
                const args = arguments
                clearTimeout(debounceTimer)
                debounceTimer
                    = setTimeout(() => func.apply(context, args), delay)
            }
        }
        const disableNext = (isDisabled = true) => {
            if (isDisabled) {
                $('.btn-next').addClass('btn-disabled');
                $('.btn-next').attr('disabled', 'disabled');
            } else {
                $('.btn-next').removeClass('btn-disabled');
                $('.btn-next').removeAttr('disabled');
            }
        }
        const validadeCpf = (strCPF) => {
            strCPF = strCPF.replace('.', '').replace('.', '').replace('-', '');
            var Soma;
            var Resto;
            Soma = 0;
            if (strCPF == "00000000000") return false;

            for (var i = 1; i <= 9; i++) 
                Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(strCPF.substring(9, 10))) return false;

            Soma = 0;
            for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(strCPF.substring(10, 11))) return false;
            return true;
        }
        const validateBirth = (strBirth) => {
            let birthday;

            if (strBirth.length < 10) {
                return false;
            } else {
                birthday = strBirth.split('/')[2] + '-' + strBirth.split('/')[1] + '-' + strBirth.split('/')[0];
            }

            if(!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
                return false;
            }
            let parts = birthday.split('-');
            let now = new Date();
            let year = parseInt(parts[0], 10);
            let currentYear = now.getFullYear();
            let month = ( parts[1][0] === '0') ? parseInt(parts[1][1], 10) : parseInt(parts[1], 10);
            let day = ( parts[2][0] === '0') ? parseInt(parts[2][1], 10) : parseInt(parts[2], 10);
        
            if(year >= currentYear) {
                return false;
            }
            if( (currentYear - year) < 18 || (currentYear - year) > 80) {
                return false;
            }
            if( month < 1 || month > 12) {
                return false;
            }
            if( day < 1 || day > 31 ) {
                return false;
            }
            return true;
        }
        const validate = (element) => {
            console.log('element', element);

            if (element.val()) {
                // Validate Length
                if (element.attr('minlength')) {
                    if (element.val().length < element.attr('minlength')) {
                        element.parents('label').removeClass('valid');
                        element.parents('label').addClass('invalid');

                        disableNext(true);
                    } else {
                        element.parents('label').removeClass('invalid');
                        element.parents('label').addClass('valid');

                        disableNext(false);
                    }
                }
                // Validate CPF
                if (element.attr('id') == 'cpf') {
                    if (validadeCpf(element.val())) {
                        element.parents('label').removeClass('invalid');
                        element.parents('label').addClass('valid');

                        disableNext(false);
                    } else {
                        element.parents('label').removeClass('valid');
                        element.parents('label').addClass('invalid');

                        disableNext(true);
                    }
                }
                // Validate Birth
                if (element.attr('id') == 'birth') {
                    if (validateBirth(element.val())) {
                        element.parents('label').removeClass('invalid');
                        element.parents('label').addClass('valid');

                        disableNext(false);
                    } else {
                        element.parents('label').removeClass('valid');
                        element.parents('label').addClass('invalid');

                        disableNext(true);
                    }
                }
                // Validate Selects
                if (element.is('select')) {
                    if (element.val() == 0) {
                        element.parents('label').removeClass('valid');
                        element.parents('label').addClass('invalid');

                        disableNext(true);
                    } else {
                        element.parents('label').removeClass('invalid');
                        element.parents('label').addClass('valid');

                        disableNext(false);
                    }
                }
            } else {
                // Validate Checkbox
                if (element[0].type == 'checkbox') {
                    if (element.is(':checked')) {
                        disableNext(false);
                    } else {
                        disableNext(true);
                    }
                } else {
                    element.parents('label').removeClass('invalid');
                    element.parents('label').removeClass('valid');
    
                    disableNext(true);
                }
            }
        }
        const crop = () => {
            $(".gambar").attr("src", "../theme/images/avatar.png");
            var $uploadCrop,
            tempFilename,
            rawImg,
            imageId;
            function readFile(input) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('.upload-demo').addClass('ready');
                        $('#cropImagePop').modal('show');
                        rawImg = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                else {
                    swal("Sorry - you're browser doesn't support the FileReader API");
                }
            }

            $uploadCrop = $('#upload-demo').croppie({
                viewport: {
                    width: 141,
                    height: 141,
                },
                enforceBoundary: false,
                enableExif: true
            });
            $('#cropImagePop').on('shown.bs.modal', function(){
                $uploadCrop.croppie('bind', {
                    url: rawImg
                }).then(function(){
                    console.log('jQuery bind complete');
                });
            });

            $('.item-img').on('change', function () { imageId = $(this).data('id'); tempFilename = $(this).val();
                                                                                                $('#cancelCropBtn').data('id', imageId); readFile(this); });
            $('#cropImageBtn').on('click', function (ev) {
                $uploadCrop.croppie('result', {
                    type: 'base64',
                    format: 'jpeg',
                    size: {width: 141, height: 141}
                }).then(function (resp) {
                    $('#base64_photo').val(resp);
                    $('#item-img-output')
                        .attr('src', resp)
                        .addClass('crop-applied');
                    $('#cropImagePop').modal('hide');

                    disableNext(false);
                });
            });
        }
        const cropMyJob = () => {
            $(".gambar").hide();
            var $uploadCrop,
            tempFilename,
            rawImg,
            imageId;
            function readFile(input) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('.upload-demo').addClass('ready');
                        $('#cropImagePop').modal('show');
                        rawImg = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                else {
                    swal("Sorry - you're browser doesn't support the FileReader API");
                }
            }

            $uploadCrop = $('#upload-demo').croppie({
                viewport: {
                    width: 180,
                    height: 268,
                },
                enforceBoundary: false,
                enableExif: true
            });
            $('#cropImagePop').on('shown.bs.modal', function(){
                $uploadCrop.croppie('bind', {
                    url: rawImg
                }).then(function(){
                    console.log('jQuery bind complete');
                });
            });

            $('.item-img').on('change', function (e) { 
                if ($(this)[0].files[0].size > 7500000) {
                    // alert('File too big!');
                    $('#validation_filesize').modal('show')
                    return false;
                }

                imageId = $(this).attr('id'); 
                tempFilename = $(this).val();

                console.log('imageId', imageId);
                console.log('tempFilename', tempFilename);
                $('#cancelCropBtn').data('id', imageId); 
                readFile(this); 
            });

            $('#cropImageBtn').on('click', function (ev) {
                $uploadCrop.croppie('result', {
                    type: 'base64',
                    format: 'jpeg',
                    size: {width: 345, height: 514}
                }).then(function (resp) {
                    $('#base64_' + imageId).val(resp);
                    $('#item-img-output_' + imageId)
                        .attr('src', resp)
                        .show()
                        .addClass('crop-applied');

                    $('#cropImagePop').modal('hide');

                    disableNext(false);
                });
            });
        }
        const btnAskBefore = () => {
            $('.btn-ask-before').on('click', function(e) {
                if ($(this).hasClass('btn-disabled')) {
                    return false;
                } else {
                    e.preventDefault();
                    $('#confirm').modal('show')
                }
            });
        }

        if ($('.form').length) {
            applyMask();
            btnAskBefore();
            
            if ($('.form').hasClass('form-myjob')) {
                cropMyJob();
            } else {
                crop();
            }

            $('.form').each(function () {
                $('.form').find('input').on('change keyup input', debounce(function () {
                    validate($(this));
                }, 300));

                $('.form').find('select').on('change', debounce(function () {
                    validate($(this));
                }, 300));
            });

            $('.btn-next').on('click', function(e) {
                if ($(this).hasClass('btn-disabled')) {
                    e.preventDefault();
                }
            });
        }
    }
}

export default FormValidation