import 'bootstrap'
import $ from 'jquery'

// Classes
import SLIDER from './modules/slick'
import FORMVALIDATION from './modules/formValidation';
import TINDERCONTROL from './modules/tinderControl';
// import FANCYBOX from './modules/fancyBox'

// Objects
// var fancybox = new FANCYBOX()


// jQuery
document.addEventListener("DOMContentLoaded", function () {
    console.log('>> Document Ready');
    
    var formValidation = new FORMVALIDATION();
    var slider = new SLIDER();
    var tinderControl = new TINDERCONTROL();

    if ($('body').hasClass('page-signup')) {
        console.log('> Signup');
    }   
    
    
    for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () =>{ 
            console.log('oi', e.value);

            $(e).parent().find('label span').html(e.value);
            return e.style.setProperty('--value', e.value);
        });
    };
});
